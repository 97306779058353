import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import FadeIn from 'react-lazyload-fadein'

import Header from '../components/Header'
import Layout from '../layouts'

function ProjectDetail({ data }) {
  const {
    title,
    paragraph,
    description,
    images,
    type,
    workinprogress,
    logo,
  } = data
  return (
    <div>
      <Header />
      <section className="main style1" style={{ position: 'relative' }}>
        <div className="backBtn">
          <Link to="/projets">
            <span
              className="icon major fa-chevron-left back"
              style={{ fontSize: 12 }}
            />
          </Link>
        </div>
        <div className="grid-wrapper">
          <div className="col-6">
            <div className="block-title">
              <h1>{title}</h1>
              <p style={{ marginTop: '-10px' }}>{paragraph}</p>
              {workinprogress === 'workinprogress' && (
                <h3 style={{ color: '#FFF' }}>En cours de travaux</h3>
              )}
              <div id="triangle-brand">
                <img src={logo} alt="" />
              </div>
            </div>
            <ul>
              <li>Type de bâtiment</li>
              <li>
                <strong style={{ textTransform: 'capitalize' }}>{type}</strong>
              </li>
              {description.map(({ title, value }) => (
                <Fragment key={value}>
                  <li>{title}</li>
                  <li>
                    <strong>{value}</strong>
                  </li>
                </Fragment>
              ))}
            </ul>
          </div>
          <div className="col-6">
            {images.map((image) => (
              <FadeIn key={image} height={600}>
                {(onload) => (
                  <img
                    src={image}
                    onLoad={onload}
                    className="photoProject"
                    style={{ marginBottom: 20 }}
                    alt={image}
                  />
                )}
              </FadeIn>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

class ProjectTemplate extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      markdownRemark: PropTypes.object.isRequired,
    }),
  }
  render() {
    return (
      <Layout location={this.props.location}>
        <ProjectDetail data={this.props.data.markdownRemark.frontmatter} />
      </Layout>
    )
  }
}

export default ProjectTemplate

export const projectTemplateQuery = graphql`
  query ProjectTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        paragraph
        type
        workinprogress
        logo
        description {
          title
          value
        }
        images
      }
    }
  }
`
